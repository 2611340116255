.hide-class{
    display: none;
  }
.ColorShow{
  display: block;
}
.ColorHide{
    display:none;
}
.remove-promo{
    padding: 5px;
    background-color: white;
    border: 1px solid #d20c14;
    border-left: 0;
}
.total-count{
    border:1px solid rgba(0,0,0,.1);
    padding: 2px 8px;
}
  .cartImage img {
    max-width: 100%;
    max-height: 100%;
  }
.spaceBetweenShippingAndBilling{
    margin-top:20px !important;
}

  .innercartimage a {
    height: 95px;
    width: 90px;
    background: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #dddddd52;
    object-fit: contain;
}

.proAddress h5 a {
  color: #333 !important;
    font-size: 18px;
    margin-bottom: 14px;
    display: inline-block;
}

.proDetail{
  margin: 0;
    font-size: 15px;
    margin-bottom: 5px;
}

.quantity-plus,
.quantity-minuus {
  border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 16px;
    line-height: 0;
    padding: 3px 2px 0px 0;
    font-weight: bold;
    position: relative;
    outline: 0 !important;
    border: 0px;
    text-align: center;
}

.number-quantity.mb-0 {
  width: 57px;
  text-align: center;
}

.quantity-minuus {
  padding: 0 !important;
}

.offerFont {
  font-size: 13px;
    display: inline-block;
}

.offerPrice {
  color: #70707061;
  text-decoration: line-through;
  margin-right: 7px;
}

.totalPrice {
  height: 100%;
}

.delIconSection{
  display: flex;
  justify-content: center;
  align-items: center;
}

.delIconSection img {
  width: 17px;
  cursor: pointer;
}

.order-title {
  font-size: 14px;
}

.orderSummary {
  margin: 0px 0 15px !important;
  font-size: 14px;
}

.currencY {
  font-size: 11px;
}

.summary-action{
  border-radius: 0px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.shipping-btn {
  background: #d20c14 !important;
    border: 1px solid #d20c14 !important;
}

.mob-title {
  display: none;
}

.quantity-minuus,
  .quantity-plus{
    background-color: #fff !important;
  }

  .mob-left {
    text-align: center;
  }

  .mob-text-align {
    text-align: center;
  }

@media screen and (min-width: 0px) and (max-width: 767px) {
  .mob-total {
    position: relative;
    height: 20px !important;
  }
  .mob-shipping {
    position: relative;
  }
  .mob-delete {
    position: absolute !important;
    bottom: 5px;
    right: -8px;
  }
  .desktop-shipping-edit {
    display: none;
  }

  .mob-shipping .mob-edit {
    bottom: 15px !important;
  }

  .quantity-plus {
    padding: 2px 1px 0px 0 !important;
  }
  .mob-edit {
    position: absolute !important;
    bottom: 0 !important;
    right: -13px !important;
  }
  .grandtotal {
    padding-left: 0 !important;
}
  .mob-edit-space{
    padding: 0 !important;
    margin: 10px 0 20px;
  }
  .mob-hide {
    display: none !important;
  }
  .mob-title {
    display: block !important;
    font-size: 20px;
    margin: 10px 0 5px;
    text-align: left;
  }
  .mob-layout-bg{
    background: #fff;
    margin-bottom: 10px;
    padding: 2px 0px 10px 0px;
    border: 1px solid #dddddd6b;
  }
  .mob-right-space{
    padding-right: 0px !important;
  }
  .quantity-minuus,
  .quantity-plus{
    background: #e5e8ea !important;
  }
  .mob-left {
    text-align: left !important;
  }
  .totalPrice{
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }
  .mob-bottom {
    margin-bottom: 10px;
  }
  .mob-text-align {
    text-align: left !important;
  }
  .mob-summary{
    padding-left: 0 !important;
    margin-top: 10px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .mob-shipping {
    display: none;
  }
  .innercartimage a{
    margin-right: 10px !important;
    width: 80px !important;
    height: 90px !important;
  }
  .tab-space {
    padding: 0 !important;
  }
  .mob-layout-bg {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px !important;
  }
}

.likeImg{
  width: 50px;
    margin-bottom: 10px;
}

@media screen and (min-width: 1023px) {
  .mob-layout-bg {
    border-bottom: 1px solid #ddd;
    padding: 0 0 16px;
  }
  .mob-shipping {
    display: none;
  }
}

.round-edit {
  width: 18px;
  margin-top: -9px;
}

input[name="promoCode"],
.promo-body-border {
  border: 1px solid #d20c14 !important;
}

input[name="promoCode"] {
    border-right: 0 !important;
}

.underline {
  position: relative;
  padding: 0px 0 10px 0;
}

.underline::after {
  content: '';
    position: absolute;
    width: 115%;
    height: 1px;
    background: #ddd;
    left: 0;
    bottom: 0;
}

@media screen and (min-width: 1024px){
  .grandtotal {
    padding-left: 35px !important;
  }
  .desk-space-left {
    padding-right: 45px !important;
  }
  .desk-space-right {
    padding-left: 45px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1253px) {
  /* 1024 x 1253 */

  .innercartimage a {
    height: 80px !important;
    width: 75px !important;
  }
}
