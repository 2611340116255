.mainAlign{
    display: flex;
    height: 89vh;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.colorChange{
    filter: invert(3%) sepia(55%) saturate(563%) hue-rotate(107deg) brightness(160%) contrast(82%);
}
.mainAlign h4 {
    font-weight: 500;
}

.download {
    border: 1px solid transparent;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 15px auto;
}

.download img {
    width: 20px;
    margin-right: 5px;
}

button[disabled] {
    opacity: 0.6;
}

.shop-link {
    text-decoration: underline;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .mainAlign {
        height: 80vh;
    }
}

.tick {
    width: 63px;
}
