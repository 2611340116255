@media screen and (min-width: 1025px){
 .header {
    display: none;
 }
}

.leftnavicon {
  width: 17px;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
  margin: 10px 0 !important;
}

.sidenav---sidenav-nav---3tvij {
  /* padding-top: 10%; */
}

.accountIcon {
  width: 14px;
}

.favIcon{
  width: 20px;
  height: auto;
}

.cartIcon{
  width: 18px;
}

.sidenav---sidenav-nav---3tvij >
.sidenav---sidenav-navitem---uwIJ- >
.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  opacity: 1 !important;
}

.menu-list {
  padding: 0% 0 2%;
}

/* backup code */


.sidenav---selected---1EK3y .workarea-sprite-WAlb {
  width: 31px;
  height: 29px;
  background-position: -5px -5px;
}
.sidenav---sidenav---_2tBP{
  background-color: transparent !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo{
  opacity: 1;
  width: 50px;
  height: 60px;
  margin: 0;
}
.sidenav---sidenav-nav---3tvij{
  padding: 0 10px;
}
.navLink svg{
  height: 23px;
  fill: #d00;
  transform: translate(-50%,-50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-{
  margin: 30px 0
}
.sidenav---sidenav-toggle---1KRjR{
  display: none;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after{
  opacity: 1 !important;
  background: #d00;
  border-radius: 50%;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T > .sidenav---navicon---3gCRo > .navLink >svg{
  fill: white;
}
.defaultClass{
  background: #f9f9f9 !important;
}
.left-sidenav {
  position: fixed;
  left: 0;
  top: 0
}
.navLink{
  height: 100%;
  display: block;
}
.navIcon {
  /* height: 33px; */
}
.header_container{
  height: 50px;
}

@media screen and (max-width: 650px) {
.sidenav---sidenav---_2tBP {
  display: none;
}
.search-popup .modal-content{
  width: 100% !important;
  margin: auto !important;
  margin-top: 20% !important;
}
}
@media screen and (min-width: 651px) {
.header_container {
  display: none;
}
}

.navList a {
color: #707070;
}

/* header */

.header {
background-color: #fff;
box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
position: fixed;
width: 100%;
z-index: 10;
top: 0;
left: 0;
}

.header ul ,.left-sidenav ul{
margin: 0;
padding: 0;
list-style: none;
overflow: hidden;
background-color: #fff;
}

.header li a ,.left-sidenav li a{
display: block;
padding: 10px 0px;
/* border-right: 1px solid #f4f4f4; */
text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
background-color: #f4f4f4;
}

.header .logo {
display: block;
float: left;
font-size: 2em;
padding: 10px 20px;
text-decoration: none;
}

.navIcon-header{
  float: left;
  height: 30px;
  margin: 7px 0;
  padding-right: 15px;
}

.navIcon-menu{
  width: 30px;
  cursor: pointer;
}

/* menu */

.header .menu {
clear: both;
max-height: 0;
transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
cursor: pointer;
display: inline-block;
/* padding: 20px 20px; */
position: relative;
user-select: none;
margin: 0;
}

.header .menu-icon .navicon {
background: #d20c14;
display: block;
height: 3px;
position: relative;
transition: background .2s ease-out;
width: 30px;
}

.navList {
  padding: 10px 20px;
  cursor: pointer;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
background: #d20c14;
content: '';
display: block;
height: 100%;
position: absolute;
transition: all .2s ease-out;
width: 100%;
}

.header .menu-icon .navicon:before {
  top: 8px;
}

.header .menu-icon .navicon:after {
top: -8px;
}

/* menu btn */

.header .menu-btn {
display: none;
}

.header .menu-btn:checked ~ .menu {
max-height: 325px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
.header li {
  float: left;
}
.header li a {
  padding: 20px 30px;
}
.header .menu {
  clear: none;
  float: right;
  max-height: none;
}
.header .menu-icon {
  display: none;
}
}
.sideNav-menu-text-active{
color: #d00 !important;
}
.sideNav-menu-text-inactive{
color: #212529 !important;
}

/* cart count */
.cartcount {
  color: #FFF !important;
  position: absolute;
  top: 8px;
  right: -6px;
  border: 1px solid transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 3px 0 0 1px !important;
}

.cartcounts {
  color:  #FFF !important;
  position: absolute;
  top: 10px;
  left: 40px;
}

.mob-menu {
  width: 50%;
  float: left;
  padding: 5% 5% 5% 5%;
}

.mob-logo {
  width: 50%;
  float: right;
  text-align: right;
  padding: 3% 4% 3% 0%;
}

.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u {
  margin: -6px 0 0 0 !important;
}

.sidenav---sidenav-nav---3tvij {
  padding-top: 25px;
  height: 200%;
  margin-left: 2px;
  box-shadow: 0 0 10px #d6d3d3;
  position: relative;
  z-index: 99;
}

@media screen and (min-width: 1023px) {
  .nav-parent {
    position: fixed;
    top: 0;
    height: 100%;
  }
  .user-login .nav-parent {
    z-index: 99;
    background: #f9f9f9!important;
  }
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after{
  display: none !important;
}
.language-header.modal-header {
  color: #fff;
  padding: 10px;
  border: unset !important;
  background: #d20c14;
}
.modal-dialog.modal-sm .close {
  color: #fff;
  opacity: 1;
  font-size: 25px;
  top: 17px;
}
.radioWrap .checkmark{
  top: -1px !important;
}
.language-popup .modal-content{
  width: 42%;
  margin: auto;
  margin-top: 12%;
}

.search-popup .modal-content{
  width: 50%;
  margin: auto;
  margin-top: 12%;
}

.search-popup button{
    border-radius: 0
}

.design-language {
  display: flex;
  padding: 10px;
}
.design-language .checkmark {
  margin-left: 90%;
}
.language-header.h4 {
  font-size: 18px;
  font-weight: 400;
}
h6 .design-language{
  font-size: 14px;
  font-weight: 400;
}
.design-language .checkmark {
  border: 2px solid rgb(244 24 18 / 20%);
}

.sidenav---sidenav-navitem---uwIJ-,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
 margin: 0 !important;
}
.language-header.modal-header .h4 {
  margin-left: 7px;
}

.design-language .radioWrap .checkmark:after {
  top: 1.5px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #d20c14;
}

.sidenav---sidenav-nav---3tvij {
  width: 80%;
  padding-left: 0;
}

.no-suggestions {
  color: #eee;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #ddd;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #eee;
  color: black;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #ddd;
}

@media screen and (min-width: 810px) and (max-width: 1080px) {
  .mob-summary{
    padding: 0 !important;
  }
  .actionbutton {
    width: 35% !important;
  }
.sidenav---sidenav-nav---3tvij {
  /* height: 200vh !important; */
}
.contentwrap {
  width: 100%;
  float: left;
}
 .selector:not(*:root), .profile-form select {
  box-shadow:none;
  outline:none;
-webkit-appearance: none;
-moz-appearance: none;
background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  border: 1px solid #dfdfdf;
  padding: 5px;
  width: 100% !important;
}
.desk-dele-icon {
  display: none !important;
}
.deleteAlign {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 12px;
}
.tab-bottom-space {
  margin-bottom: 10px !important;
}

}

.design-language .radioWrap .checkmark {
    height: 19px !important;
    width: 19px !important;
}

.design-language .radioWrap .checkmark:after {
  width: 11px !important;
  height: 11px !important;

}

.dutch   .checkmark:after {
  left: 2px !important;
  top: 2px;
}

.french   .checkmark:after {
  top: 2px !important;
}

@media screen and (min-width: 0px ) and (max-width: 767px) {
  .user-login .header {
    padding: 12.5px 0 6px !important;
  }

  .tab-bottom-space {
    margin-bottom: 10px !important;
  }


}
