.filter-footer button{
  background-color: #c82333 !important;
  border-color: #dc3545 !important;
  float: right;
  padding: 8px 20px 8px 20px;
  font-size: 14px;
}

.label-class{
  color: black
}
.data-filter{
  margin-left:50px;
  margin-top:-50px;
}
.list_class{
  list-style-type: none
}
.filter_list {
  display: inline-flex;
}
.filter-modal{
  max-height: 100vh
}
/* .modal-dialog-scrollable .modal-body{
  max-height: 70vh
} */
.checkBox{
  position: absolute;
  top: 0;left: 0;
  height: 10px;
  width: 10px;
}
.popup ,.modal-dialog{
  max-width: 1000px !important
}
.modal-content {
 border:none !important;
}
.modal-dialog-scrollable {
  margin-top: 5% !important;
}
p.text-danger.w-100.float-left {
  margin-left: 10px;
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 12px;
}

.filterhead h3{
    background: #d20c14;
   padding-left: 3.3% !important;
   color: white;
   padding: 8px;
   font-weight: 600;
}
.filter-popup-body {
  padding-top: 0px !important;
}
.filterhead {
  position: absolute;
  width: 100%;
  z-index: 1;
}
.scrolfixed {
   margin-top: 2%;
   overflow-y: auto;
   height: 400px;
   overflow-x: hidden;
   padding: 15px;
} 
.alignmentForButtons{
    float:right;
    margin-right:5px;
}

.popup-filter-price {
  opacity: 0;
}
.desktop{
    display: block !important;
}
.mobile{
    display: none !important;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto !important;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e5e8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#e0e5e8;
}
/* .scrolfixed::-webkit-scrollbar {
  width: 5px;
  background: #e0e5e8;
} */
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  z-index: 99;
  position: absolute;
  right: 20px;
  font-size: 20px;
}
.filter-footer-popup button {
  float: right;
  padding: 8px 20px 8px 20px;
  font-size: 14px;
}
.filter-footer-popup {
  padding: 35px !important;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .desktop{
        display: none !important;
    }
    .mobile{
        display: block !important;
    }
    .modal-dialog-scrollable .modal-body {
        overflow-y: auto !important;
    }
    .alignmentForButtons{
        float:right;
        margin-right:5px;
    }
    .RowAlter{
      padding-bottom: 10px !important;
      justify-content: center;
      border-bottom: 1px solid #ddd;
    }
    .arrowalter{
        float:right
    }
    .checkboxdivalter{
        margin-top:20px;
    }
  .mobile {
      background: #fff;
  }
    .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: block;
    width: 101%;
    height: 100%;
    overflow: hidden;
    outline: 0;
   }
   .fade.modal.show {
    padding-right: 0px !important;
}
   .mobile-header {
    background: #dc3545;
    color: #fff;
}
.arrowalter img {
  color: #000;
  width: 11px;
  height: 20px;
}
/* .fade.modal-backdrop.show {
  background: #fff;
  opacity: 2;
  border: 0px solid !important;
  box-shadow: none;
} */
.mobile-view-css .close {
  color: #fff;
  opacity: 1;
  font-size: 28px;
}
.mobile-footer {
  border: none !important;
}
.applybutton {
  margin-right: 6% !important;
  padding: 10px !important;
  border-radius: unset !important;
}
.checkWrap {
  font-weight: 500 !important;
}
.cancelbutton {
  margin-right: 35% !important;
  padding: 10px !important;
  border-radius: unset !important;
}
  .mobile-view-css {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.mobile-footer{
  height: 130px;
}
.language-popup .modal-content{
  width: 100% !important;
  margin: auto !important;
  margin-top: 20% !important;
}
}




  .responsive-filter {
    background: #d20c14;
    color: white;
    font-weight: bolder;
}
.body-filter {
  height: 516px;
}
.checkboxdivalter {
  font-size: 17px !important;
  font-weight: 600 !important;
}
button.cancelbutton {
  color: #d20c14;
  background: white !important;
}
.text-color {
  color: #d20c14;
  /* font-weight: bold; */
}
.filter-footer-popup {
  padding: 30px !important;
}
p.text-colors {
  margin-left: 10px !important;
  color: #d20c14;
}
p.text-color {
  margin-left: 10px !important;
  margin-top: 24px;
}
.price-checkmark {
  position: absolute;
  top: 0;
  left: 16px;
  height: 22px;
  width: 22px;
  background-color: #eee;
}
.filter-footer-popup button {
  border-radius: unset;
  background: #d20c14;
  border-color:#d20c14 ;
  font-weight: 600;
}

.filter-footer-popup button:hover {
  background-color: #d20c14 !important;
  border: 1px solid #d20c14 !important;
}

.filter-close-popup .close {
  color: #fff !important;
  font-size: 24px;
  opacity: 1;
}
.filter-close-popup .checkWrap .checkmark {
  position: absolute;
  left: 0;
  height: 17px;
  width: 18px;
  background: unset;
}
.modal-backdrop {
  background: none !important;
}
.checkboxs .checkmark {
  position: absolute;
  top: -4px;
  left: 18px;
  height: 22px;
  width: 22px;
  background-color: #eee;
}
.filter-close-popup .modal-content {
  width: 90%;
  margin: auto;
}
.filter-footer-popup .btn-primary {
    color: #fff;
    background-color: #d20c14 !important;
    border-color: #d20c14 !important;
}

.filter-close-popup .modal-content {
  border-radius: 0 !important;
}

@-moz-document url-prefix() {
  .scrolfixed {
    overflow-y: scroll;
    scrollbar-color: #e0e5e8 #fff;
    scrollbar-width: thin;
  }
}

.filter-close-popup .checkWrap .checkmark:after {
  left: 6px !important;
  top: 1px !important;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .filter_list {
    padding: 0 !important;
    margin-left: -2px;
  }
  .filter_list .list_class {
    width: 100%;
  }
  .filter_list .list_class label span:first-child {
    display: inline-block;
    margin-left: 15px;
    font-weight: normal;
  }

  .fade.mobile.modal.show {
    background-color: #f9f9f9 !important;
  }
  .mobile-view-css .modal-body {
    padding: 0 !important;
  }
}