/* .products {
  display: flex;
  flex-wrap: wrap;
}
.product-card {
  flex: 1 21%;
}
.product-image img {
  max-width: 100%;
}
.product-info {
  margin-top: auto;
}
@media (max-width: 920px) {
  .product-card {
    flex: 1 21%;
  }
}

@media (max-width: 600px) {
  .product-card {
    flex: 1 46%;
  }
} */

/* .layout-space {
  margin: 0% 4.25% 1% 8.4%;
  
} */

@media screen and (min-width: 0px) and (max-width: 767px) {
  .layout-space {
    padding: 0 5%;
    margin: 0 !important;
  }
}

/* @media screen and (min-width: 1681px) and (max-width: 1920px) {
  .layout-space {
    margin: 0% 4.25% 1% 7.1% !important;
  }
} */

@media screen and (min-width: 0px) and (max-width: 767px) and (orientation: landscape) {
  .layout-space {
    margin: 0 0% 4% 8% !important;
  }
  .nav-parent {
    position: fixed;
    top:0;
    height: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1253px) {
  .layout-space {
    margin: 0% 4.5% 1% 9.4% !important;
  }
}



@media screen and (min-width: 768px) and (max-width: 1023px) {
  .layout-space {
    margin: 0% 4% 1% 11% !important;
  }
  .nav-parent {
    height: 100%;
  }
  .products_main {
    margin-bottom: 25px;
}
}


.menu-list ul {
  margin: 0;
  padding: 0;
}

.menu-list ul li {
  display: inline-block;
  padding: 0 10px;
}

.menu-list ul {
  color: #3D3D3D;
  font-size: 12px;
}

.product-image{
  display: flex;
  height: 280px;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #fff;
  width: 100%;
  cursor: pointer;
  border: 1px solid #dddddd94;
}

.product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.products_main {
  margin-bottom: 10px;
}

.catagory-section li {
  display: inline-block;
  }
  li.item-name {
    text-transform: capitalize;
    font-size: 14px;
  }

  .filtered-data {
    background: #fff;
    padding: 3px 0px 3px 11px;
    border: 1px solid #ddd;
    border-radius: 25px;
    margin: 5px;
    font-size: 14px;
  }

  .remove-filter-item {
    display: inline-block;
    margin-left: 10px;
    padding-right: 12px;
    font-size: 13px;
  }


  .catagory-section {
    padding: 0;
    display: inline;
    margin-right: 5px;
  }

  .no-pro{
    width: 100%;
      float: left;
      margin-top: 50px !important;
  }

.categoryWrap {
  margin-top: 30px;
}

