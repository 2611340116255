
.order-details-row{
  padding: 5px;
  height: 60px;
  margin-top:3px;
  
  
}
.detaild{
  float: left;
}
.pickup-menu{
    display: inline-block;
    margin:5px;
  }
  .pickup-menu li a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    padding: 10px 25px 6px 20px;
    position: relative;
  }
  .pickup-menu li a.btn-active::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #d20c14;
  }
  .loc-btn{
    background-color: #d20c14;
  }
  .location-tbl{
    padding:10px;
    margin:10px;
  }
  .tr-loc{
    padding:20px;
    margin: 10px;
  }
  /* table tr td{
   margin:10px;
   padding:30px;
  } */
  
  .pickup-btn{
    margin-left:5px;
    padding: 3px;
    width:90px;
    height: 40px;
    color: white;
    font-weight: bold;
    border: none;
    background-color: #ddd;
    /* border: 1px solid #ddd; */
    border-color: #ddd;
  }
  .pickup-search-btn{
    padding:10px;
    text-align: left;
  
  }
  .table-head{
    background-color:#d20c14; 
    color: white;
    font-weight: bold;
    padding: 15px !important;
  }
  .scan-btn{
    margin-left:5px;
    color: #fff;
    background-color: #d20c14 !important;
    border: 1px solid #d20c14 !important;
    padding: 3px;
    width:90px;
    height: 37px;
    font-weight: bold;
    border: none;
  }

  .clear-btn{
    margin-left:5px;
    color: #fff;
    background-color: lightgray !important;
    border: 1px solid lightgray !important;
    padding: 3px;
    width:90px;
    height: 37px;
    font-weight: bold;
    border: none;
  }
  .save-btn{
    margin-left:39px;
    color: #fff;
    background-color: #d20c14 !important;
    border: 1px solid #d20c14 !important;
    padding: 3px;
    width:90px;
    height: 40px;
    font-weight: bold;
    border: none;
  }
  .save-btn-scan{
    margin-right: 6px;
    color: #fff;
    background-color: #d20c14 !important;
    border: 1px solid #d20c14 !important;
    padding: 3px;
    width:90px;
    height: 40px;
    font-weight: bold;
    border: none;
   /* text-align: right; */
    float: right;
  }

  .cancel-btn{
    margin-left:0px;
    color: #fff;
    background-color: lightgray !important;
    border: lightgray !important;
    padding: 3px;
    width:90px;
    height: 40px;
    font-weight: bold;
    border: none;
  }

  .msg{
    text-align: center;
  }
 
  .orders-list-container{
    height: 35vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .site-link{
    margin-left: 170px;
    color:#d20c14; 
    text-decoration: none !important;
  }
  .site-link:hover{
    color:#d20c14; 
    text-decoration: none !important;
  }

  .scan-field:focus {
    border: 2px solid #d20c14 !important;
    border-radius: .25rem;
  }
.scan-field{
  /* height: 45px; */
  /* width: 90%; */
  width: 100% !important;
  margin-left: -1px !important;
}

.scan-field-confirm{
  width: 88% !important;
  margin-left: 22px !important;
}

.scan-field-confirm:focus {
  border: 2px solid #d20c14 !important;
  border-radius: .25rem;
}


.Scan-text{
  font-weight: bold;
  font-size: large;
}
.border-less-fields{
  border: none;
}

.go-to-site{

  color:#d20c14;
  text-decoration: none !important;
}
.text-align-right {
  text-align: right;
}
.go-to-site:hover{
  color:#d20c14; 
  text-decoration: none !important;
}

.lan-select:focus {
  border-color:#d20c14 !important;
  padding: 2px;
  width: 100%;
  border-radius: .25rem;
}
.search-fields:focus{
  border-color: #ddd !important;
}
.search-fields:hover{
  border-color: #ddd !important;
}
.details-text{
  margin-top:15px;
  margin-left:5px;
}
@media screen and (min-width: 0px) and (max-width: 767px) {
  .go-to-site {

    color:#d20c14;
    text-decoration: none !important;
    font-size: 9px !important;
}
}
@media screen and (min-width: 0px) and (max-width: 767px) {
  .order-details-box {
    margin-left: 1%;
    margin-top: 2%;
   
}
}
@media screen and (min-width: 0px) and (max-width: 767px) {
  .site-link {
    margin-left: 30%;
    color:#d20c14;
    text-decoration: none !important;
}
}

/* tab designs */
.orders-menu1 li a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  padding:10px 25px 6px 0px;
  position: relative;
}

.orders-menu1 li a.btn-active::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d20c14;
}

.orders-menu1 li {
  display: inline-block;
  margin-top: 16px;
}

.orders-menu1 {
  margin: 0;
  padding: 0;
}

.order-table {
  background: #fff;
}

.order-info {
  font-weight: normal;
  font-size: 15px;
  word-break: break-word;
}

.order-arrow{
  display: flex;
  /* display: block !important; */
  /* justify-content: flex-end; */
  /* align-items: center; */
}

.orderNumber {
  font-size: 13px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .orders-menu1 li a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    padding: 5px 5px 5px 2px;
    position: relative;
    
}
.orders-menu1 {
  width: 100%;
  float: left;
}
.orders-menu1 li {
  display: inline-block;
  margin-top: 16px;
  width: 33.33%;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
  float: left;
}
}
.tab-table{
  padding: 10px 25px 6px 20px;

}
.edit-field{
  width: 100%;
}
.order-details-box1{
  margin-left: 25%;
  padding: 30px;
}
.edit-save-btn{
  text-align: right;
  background-color: white;
  padding: 5px;
}
.confirm-submit{
  width:100% !important;
}


@media screen and (min-width: 0px) and (max-width: 767px) {
  .order-details-box1 {
    margin-left: 0%;
    
}
}
.edit-field:focus {
  border: 2px solid #d20c14 !important;
  border-radius: .25rem;
}
.scan-borderless{
  /* border: none !important; */
  background-color: transparent !important;
  
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .edit-save-btn {
    text-align: right;
    
}
.edit_back_btn {
  margin-left: 15px;
}
.site-history{
  font-size: 8px !important;
}
.pro_class1{
  margin-bottom: 70px;
}
.mobile_space{
  margin-bottom: 30px !important;
}
}
.search-fields::placeholder {
  color: #dddf !important;
}
.arrow-text{
  float: left;
  text-align: right;
  display: inline;
}

.site-history{
  color: #fdfdfd;
}
.back-history{
  float: left;
}
.scan-btn-scan{
  color: #fff;
  background-color: #d20c14 !important;
  border: 1px solid #d20c14 !important;
  /* padding: 3px; */
  width:90%;
  height: 36px;
  font-weight: bold;
  border: none;
  /* justify-content: center; */
}
.scan-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 767px) and (max-width: 1023px){
  .pro_class{
    padding-left: 52px !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px){
  .pro_class{
    padding-left: 52px !important;
  }
}
@media screen and (min-width: 767px) and (max-width: 1023px){
  .pro_class1{
    padding-left: 87px !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px){
  .pro_class1{
    padding-left: 65px !important;
  }
}