.top-row{
    margin: 0 !important;
    margin-top:5% !important;
}
#myPortal{
    object-fit: contain !important;
}
.marginAlign{
    margin-top:-2px !important;
}
.breadcrumbButton{
    margin-top: -26px;
    color: #707070 !important;
    text-decoration: none !important;
    font-size: 13px !important;
    background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
.colorofoutofstock{
    color:red !important
}
.buttonalter,.buttonalter1{
    width:25%;
    height:50px;
    margin-top:20px;
}
.borderhighlight{
}
.borderColorHighlight{
    border:1px solid red !important;
}
/* .quantityNumbermargintop{
    font-size: initial;
} */
.vedioDivForMobile{
    width:100% !important;
}
.buttonDiv{
    margin-top: 4%;
}
.mobiledesignmainimage{
    display:none
}
.vedioDiv{
    width: 98%;
    height: 100%;
    background: black;
}
.subimagesDiv{

width: 105% !important;
margin: auto !important;
justify-content: left;


    /* padding-left: 67%; */
}

.image-gallery-icon{
    display: none;
}
.displayOfThumbnail{
    display: inline-flex !important;

}
.img-thumbnail {
    padding: .25rem !important;
    background-color: #fff !important;
    border: 1px solid #dee2e6 !important;
    border-radius: .25rem !important;

    height: 100% !important;
    width: min-content !important;
    object-fit: contain;
}
.imagesFixedSize,
.vedioDiv{
    width: 66px !important;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 8px 10px 0;
    height: 64px !important;

}

.vedioDiv {
    margin-bottom: 0 !important;
    border: 1px solid #ddd !important;
    padding: 5px;
    background: #fff;
    border-radius: 4px;
}



.adjustingSizeOfThumbnail{
    width:100px !important;
    height:100px !important;
    margin:30px;
}

/* .addcartButton {
    width: 25%;
    padding-top: 5px;
    padding-bottom: 6px;
    border:1px solid red;
}
.buyNowButton{
    background-color: red;
    width: 25%;
    padding-top: 7px;
    padding-bottom: 6px;
    margin-left: 10px;
} */

.image-gallery-thumbnails-wrapper{
    margin-top:7% !important;
}
 .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    height:100px;
    border-radius: 15% !important;
}
/* .opacityofcolor {
    border: 2px solid red;
    padding-top: 5px;
    margin-top: -6px;
} */
/* .opacityofsize{
    border: 2px solid red;
    padding-top: 5px;

} */


.stockFontsize{
    font-size: 10px;
}
.colorsDisplayStyle{
    display:inline-flex;
    margin-left:-8px;
}
.circle {
  padding: 9px;
}
/* .square {
  width: 40px;
  height: 32px;
  background-color: lightgrey;
  margin:0px 10px;
} */
/* .sizesFontStyle {
    padding-right: 8px;
    margin-top: 4px !important;
    margin-left: 8px;
    font-size: 12px;
    text-align: center !important;
} */
@media (max-width: 1000px) {
    .lapdesignmainimage{
        display:none;
    }
    .subimagesDiv{
        /* width: 100% !important;
        height: auto !important;
        padding: initial; */
        width: 105% !important;
    }
    .mobiledesignmainimage{
        width:100%;
        display: block;
        height: 400px;
        background: white;
    }
    .mobileImageWidthSetting{
        width: 100%;
       height: 100%;
       object-fit: contain;
    }
 .vedioDivForMobile{
        width:100% !important;
    }
    .mainImageDiv {
    width: 94%;
    margin-top: -15px;
}

    /* .addcartButton {
        width: 46%;
        padding-top: 5px;
        padding-bottom: 6px;
        border:1px solid red;
    }
    .buyNowButton{
        background-color: red;
        width: 46%;
        padding-top: 7px;
        padding-bottom: 6px;
    } */

    .spacingright{
        margin-left:0%;

    }
    .image-gallery-thumbnails-wrapper{
        display: none !important;
    }
    .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right{
        width: 100%;
    }
    .image-gallery-icon{
        display: inherit;
    }
}

.right-content{
    overflow: hidden;
}
.mainimageStylesChange{
    width:100% !important;
    cursor:pointer

}
/* .colorBorder{
    margin-left:8%;
} */
/* .sizeCssChanges{
    margin-left:.5%;
} */
.fixedHoverPosition{
    display: inline-block;
    position: absolute;
    margin-left: -75px;
    width: 100%;
    margin-top: 24px;
    z-index: 99;
}

.detail_img_section {
    margin: 2% 4% 2% 4%;
}

.fontweight {
    font-weight: 500;
    font-size: 14px;
}

.colorSection {
    width: 28px;
    height: 28px;
    margin: 0 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    outline: 0 !important;
}

.colorsDisplayStyle {
    height: 16px !important;
    border-radius: 50%;
    outline: 0 !important;
    border: 1px solid transparent;
    margin: auto;
    text-align: center;
}

.colorSection.opacityofcolor {
    border: 2px solid #d20c14;
    border-radius: 50%;
    outline: 0 !important;
}

.sizeCssChange {
    display: inline-flex;
}

.sizesFontStyle {
    margin-right: 10px;
    min-width: 30px;
    height: 28px;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 13px;
    outline: 0;
    font-weight: bold;
    color: #333;
    padding: 0 5px;
}

.stockFontsize {
    font-weight: 500;
    color: #000;
}

.quantity-circle{
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 16px;
    line-height: 0;
    padding: 0px 2px 0px 0;
    letter-spacing: -3px;
    font-weight: bold;
    position: relative;
    outline: 0 !important;
    border: 0px;
}

.minus {
    position: absolute;
    left: -3px;
    right: 0;
    top: 12px;
    z-index: 9;
}

.plus {
    position: absolute;
    left: -3px;
    right: 0;
    top: 13px;
    z-index: 9;
}

.number-quantity {
    margin: 0;
    width: 35px;
    font-size: 14px !important;
    height: 26px;
    margin-top: 4px;
    font-weight: bold;
}

.actionbutton {
    margin-right: 12px;
    padding: 5px 10px;
    border: 1px solid #d20c14;
    font-size: 14px;
    width: 30%;
}

.addcartButton {
    background-color: #fff;
    color:#d20c14;
}

.videoAlign {
    width: 360px;
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddddddbd;
    margin-bottom: 20px;
}

.imageAlign {
    margin-bottom: 20px;
}

@media screen and (min-width: 1024px) {
    .imagesFixedSize:nth-child(5),
.imagesFixedSize:nth-child(10) {
    margin-right: 0 !important;
}
.margin-right-space {
    padding-right: 1px !important;
    text-align: end;
}
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
    .margin-right-space {
        margin-right: 4px;
        text-align: end;
    }
    .addcartButton{
        width:35%;
        padding-bottom: 6.1px;
    }
    .buyNowButton{
        width:35%;
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .imagesFixedSize, .vedioDiv {
        margin: 0 7px 10px 0;
    }
    .detail_img_section {
        padding-left: 0 !important;
    }
    .actionbutton {
        width: 45% !important;
        margin-right: 12px;
        padding: 12px 10px;
        border: 1px solid #d20c14;
        font-size: 14px;
    }
    .margin-right-space {
        text-align: right;
        display: inline-block;
        padding-right: 1px !important;
    }
}


@media screen and (min-width: 1024px) and ( max-width: 1253px) {
    .detail_img_section {
        margin-right: 105px !important;
    }
}
.main-product-overview,.enlarged-image{
  object-fit: contain;
}

.product-list-view {
    height: 170px;
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
