/* .row{
  margin-top: 30px;
} */
/* .product-image img{
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.product-image{
    height: 275px;
} */

/* .product-image {
    display: flex;
    height: 260px;
} */
.loading{
    position: absolute;
    bottom: 40px;
}

.productsView-backButton{
    float: left;
    margin-left: 10px;
    margin-top: 0px;
    z-index: 9;
    position: relative;
}

.productsView-backButton-nofilter{
    margin-top: 28px;
    margin-left: 12px;
}

.original_price{
    text-decoration: line-through;
    /* margin: 1px 0 5px; */
    font-size: 12px;
    display: inline-block;
}
.sale_price{
    /* margin: 1px 0 5px; */
    font-size: 12px;
    display: inline-block;
}

.select_size{
    width: 108%;
    padding: 4px 5px;
    font-size: 12px;
    border: 1px solid #d20c14;
    height: 34px;
    color: grey;
    -webkit-appearance: none;
    appearance: none;
    margin-left: -4px;
  background: url('../commoncss/images/down-arrow.png') 95% / 9.5% no-repeat white;
}

.product_filters{
    width: 20%;
    font-size: 14px;
    margin: 2% 1%;
}

@media screen and (max-width: 575px) {
  .product_filters {
    width: 100%;
  }

  .productsView-backButton{
      float: none;
      margin-left: 0;
      margin-top:9%;
  }

  .productsView-backButton-nofilter{
      margin-left: 0;
  }

}

@media screen and (min-width: 575px) and (max-width: 767px) {
  .product_filters {
    width: 48%;
  }
  .productsView-backButton, .productsView-backButton-nofilter{
      margin-left: 0;
  }

}
@media screen and (min-width: 640px) and (max-width: 767px) {

  .productsView-backButton, .productsView-backButton-nofilter{
      margin-left:10px;
  }

}
@media screen and (min-width: 768px) and (max-width: 900px) {

  .productsView-backButton, .productsView-backButton-nofilter{
      margin-left: 10px;
  }

}

 .products-overlay{
     font-size: 15px;
     margin-bottom: 30px;
 }

 .product-info{
     font-size: 14px;
 }

.css-yk16xz-control{
    border-radius: 0px !important;
}

#Capa_11{
    width: 35px;
    display: inline-block;
    height: 34px;
    background: #d20c14;
    cursor: pointer;
}

#Capa_11 img {
    width: 100%;
    padding: 4px;
}

.filter_btn{
    background-color: #d00 !important;
    border: 0px !important;
    border-radius: 0 !important;
width: 100%;
font-size: 14px !important;
height: 38px;
}

.filter_btn_div{
    margin: auto 1%;
    width: 80px;
}

.products_main{
    width: auto;
}
select{
    cursor: pointer;
}

@media screen and (min-width: 1254px) {
    .cart-margin-align{
        margin-right: -5px !important;
    }
}
