.search_location {
    border: 1px solid #ddd;
    border-radius: 0px !important;
}

.search-button{
    width: auto;
    padding: 6px 20px !important;
    font-weight: bold !important;
    text-transform: uppercase;
    font-size: 14px !important;
    background: #d20c14 !important;
    border: 1px solid #d20c14 !important;
    border-radius: 0 !important;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .search_location {
        width: 57% !important;
    }
    .mob-number {
        margin-top: 10px;
    }
}
