.privacyPolicyDisclaimerColor{
    color:#d20c14;
    margin-bottom:30px !important;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
.alignCenter{
    margin-right:25px;
    margin-left:25px;
    margin-bottom: 15px;
}
.privacypolicyBackButton {
    margin-left: -1% !important;
        margin-top:34px !important;

}
.disclaimerBackButton{
    margin-left:-1% !important;
        margin-top:34px !important;
}

}
@media screen and (min-width: 768px) and (max-width: 1250px) {


    .privacypolicyBackButton {
        margin-left: -15% !important;
            margin-top:34px !important;

    }
    .disclaimerBackButton{
        margin-left:-15% !important;
            margin-top:34px !important;
    }

}
@media screen and (min-width: 1250px) {


    .privacypolicyBackButton {
        margin-left: -15% !important;
        margin-top:34px !important;

    }
    .disclaimerBackButton{
        margin-left:-15% !important;
        margin-top:34px !important;
    }


}
