/* .salesBanner img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  border: 1px solid transparent;
  border-radius: 20px 0 0px 20px;
  object-fit: contain;
  object-position: left top;
} */

.mobile-salesBanner img {
  height: 100%;
  width: 100%;
}

.salesContent {
  position: relative;
}

.upcoming-ribbon-color{
    background-color: #545960
}

.mobile-ribbon, .sale-description-mobile {
    color: #fff;
    /* border-radius: 15px; */
    text-align: center;
}

.blur-overlay-mobile {
    opacity: 0.4;
}

.SalesOffer {
  position: absolute;
  top: 0;
  right: 0;
}

.blur-overlay {
  position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.4;
}
.blur-overlay-image{
  opacity: 0.4;
}

/* .blur-overlay img {
  filter: blur(3px);
} */

.text-blur{
  color: #ffffffc9;
    text-shadow: 0 0 3px rgba(255,255,255,0.5);
}

.SalesOffer p {
  position: relative;
  z-index: 99;
}

.saleswrap {
  width: 350px;
  right: 0;
  position: absolute;
  text-align: center;
}

.homeribbon {
  position: relative;
  margin-top: 39px;
}

.homeribbon h5 {
  position: relative;
  z-index: 99;
  color: #fff;
}

.homeribbon::after {
  content: '';
    position: absolute;
    box-sizing: content-box;
    width: 376px;
    height: 0;
    border: 49px solid rgba(0,0,0,0);
    border-top: 0 solid;
    border-bottom: 40px solid #d20c14;
    border-right: 0px;
    right: 0;
    top: 0;
    top: -8px;
    z-index: 9;
}

.homeribbon::before {
  content: '';
    position: absolute;
    width: 21px;
    height: 31px;
    border: 17px solid #ffffffe0;
    top: 31px;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(50deg) translate(2px, -30px) skew(3deg, -16deg);
    left: -91px;
}

.discount,
.sales-end {
  position: relative;
  z-index: 9;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 68px;
  margin: 0 !important;
}

.discount p,
.sales-end p {
  margin-bottom: 2px;
}

.trapezoid-upcoming::before {
  /* filter: blur(3px); */
  border-left: 0;
}

.trapezoid-upcoming::after {
  /* filter: blur(3px); */
  border-right: 0;
}

.end-of-sale-font{
    font-size: 15px;
}

@media screen and (min-width: 1024px) {
  .SalesOffer {
    width: 245px;
 }
 .mobile-salesBanner, .salesBanner {
  height: 140px;
 }
 .trapezoid::after{
  content: '';
  position: absolute;
  box-sizing: content-box;
  width: 100px;
  height: 0;
  border: 169px solid rgba(0,0,0,0);
  border-top: 0 solid;
  border-bottom: 139px solid #555A60;
  border-right: 0px;
  top: 0;
  right: 190px;
}

.trapezoid::before{
  content: '';
  position: absolute;
  width: 192px;
  background: #555A60;
  height: 139px;
  right: 0;
  border: 1px solid transparent;
  border-radius: 0 20px 20px 0px;
}

}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .homeribbon h5 {
    font-size: 14px;
    margin: 0 !important;
    padding-top: 5px;
}
  .sales-upcoming {
    height: 150px;
    padding: 0 !important;
  }
  .mob-upsale-height {
    height: 300px !important;
  }
  .mobile-salesBanner,
  .salesBanner,
  .SalesOffer {
    height: 120px;
 }
 /* .mobile-salesBanner img, .salesBanner img {
  height: 100%;
 } */
 .trapezoid::after{
    content: '';
    position: absolute;
    background: #555A60;
    width: 60px;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: 0px 20px 20px 0;
 }
 .trapezoid::before {
  content: '';
  position: absolute;
  width: 118px;
  height: 0;
  border: 83px solid rgba(0,0,0,0);
  border-top: 0 solid;
  border-bottom: 120px solid #555A60;
  border-right: 0px;
  right: 58px;
  z-index: 9;
}
  .homeribbon {
    margin-top: 30px !important;
  }
  .homeribbon::after {
    width: 133px !important;
    border-width: 0 0px 40px 33px!important;
 }
 .saleswrap {
   width: 140px !important ;
 }
 .homeribbon::before {
  left: -41px !important;
 }
 .discount, .sales-end {
  margin-top: 17px !important;
 }
 .discount h6{
  font-size: 12px !important;
 }
}




/* Upcoming section trapezoid */

@media screen and (min-width: 1024px) {
  .sales-upcoming .trapezoid-upcoming::before {
    width: 117px !important;

  }
  .tab-upcoming  .upcomin-announce {
    margin-top: 8%;
  }


  .sales-upcoming .trapezoid-upcoming::after {
    width: 44px !important;
    right: 115px !important;
  }
  .sales-upcoming .saleswrap {
    width: 175px !important;
  }
  .text-big{
    font-size: 60px;
    margin-bottom: 0;
  }
.trapezoid-upcoming::before {
  content: '';
  position: absolute;
  width: 196px;
  background: #555A60;
  height: 139px;
  right: 0;
  border: 1px solid transparent;
  border-radius: 0 20px 20px 0px;
}

.trapezoid-upcoming::after {
  content: '';
  position: absolute;
  box-sizing: content-box;
  width: 100px;
  height: 0;
  border: 169px solid rgba(0,0,0,0);
  border-top: 0 solid;
  border-bottom: 139px solid #555A60;
  border-right: 0px;
  top: 0;
  right: 187px;
}

.upcomin-announce {
  position: relative;
  z-index: 99;
}

}

.subs-button{
  padding: 5px 15px;
  margin-top: 8px;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%,-50%);
}

/* Upcoming mobile trapezoid */

@media screen and (min-width: 0px) and (max-width: 767px) {
  .discount p {
    font-size: 14px !important;
  }

  .salesBanner img {
    /* border-radius: 20px !important; */
  }
  .trapezoid-upcoming::after{
    content: '';
    position: absolute;
    background: #555A60;
    width: 63px;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: 0px 20px 20px 0;
 }
 .trapezoid-upcoming::before{
  content: '';
    position: absolute;
    width: 118px;
    height: 0;
    border: 83px solid rgba(0,0,0,0);
    border-top: 0 solid;
    border-bottom: 120px solid #555A60;
    border-right: 0px;
    right: 58px;
    z-index: 9;
 }
  .upcomin-announce {
    position: relative;
    z-index: 99;
 }
 .tab-upcoming  .upcomin-announce {
    margin-top: 8%;
  }
  .sales-upcoming .upcomin-announce {
    margin-top: 10%;
  }
  .trapezoid-upcoming .saleswrap {
    width: 115px !important;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .trapezoid-upcoming .saleswrap   .upcomin-announce {
    margin-top: 0 !important;
  }

  .trapezoid-upcoming .saleswrap  .upcomin-announce {
    margin-top: 30px !important;
  }

  .trapezoid-upcoming .saleswrap  .upcomin-announce h6{
    font-size: 13px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sales-upcoming .saleswrap {
    width: 105px !important;
    z-index: 9;
    font-size: 13px;
}
  .tab-upcoming .saleswrap {
    width: 250px !important;
    z-index: 9;
    padding-top: 22%;
  }
  .sales-upcoming .trapezoid-upcoming::after{
    content: '';
    position: absolute;
    box-sizing: content-box;
    width: 29px;
    height: 0;
    border: 103px solid rgba(0,0,0,0);
    border-top: 0 solid;
    border-bottom: 149px solid #555A60;
    border-right: 0px;
    top: 0;
    right: 56px;
  }


  .sales-upcoming .trapezoid-upcoming::before{
    content: '';
    position: absolute;
    width: 63px;
    background: #555A60;
    height: 149px;
    right: 0;
    border: 1px solid transparent;
    border-radius: 0 20px 20px 0px;
   }
  .live-flashsales.sale-section {
    /* padding-left: 25px; */
  }
  .SalesOffer {
    width: 245px;
 }
 .salesBanner {
  height: 150px;
 }

 .trapezoid-upcoming::after{
  content: '';
  position: absolute;
  box-sizing: content-box;
  width: 100px;
  height: 0;
  border: 169px solid rgba(0,0,0,0);
  border-top: 0 solid;
  border-bottom: 149px solid #555A60;
  border-right: 0px;
  top: 0;
  right: 66px;
}

.trapezoid-upcoming::before{
  content: '';
  position: absolute;
  width: 130px;
  background: #555A60;
  height: 149px;
  right: 0;
  border: 1px solid transparent;
  border-radius: 0 20px 20px 0px;
 }

 .trapezoid::after{
  content: '';
  position: absolute;
  box-sizing: content-box;
  width: 100px;
  height: 0;
  border: 169px solid rgba(0,0,0,0);
  border-top: 0 solid;
  border-bottom: 149px solid #555A60;
  border-right: 0px;
  top: 0;
  right: 66px;
}

.trapezoid::before{
  content: '';
  position: absolute;
  width: 130px;
  background: #555A60;
  height: 149px;
  right: 0;
  border: 1px solid transparent;
  border-radius: 0 20px 20px 0px;
 }
 .saleswrap {
  width: 250px !important;
 }
 .homeribbon::after {
   width: 275px !important;
 }
 .homeribbon::before {
  left: -81px !important;
    border: 23px solid #ffffffe0 !important;
    top: 25px;
 }
}

.discount,
.sales-end {
  position: relative;
  z-index: 9;
  margin-top: 22px;
  color: #fff;
}

.upcoming-sale-height {
  height: 170px;
}

.sales-upcoming:nth-child(odd) {
  padding-left: 0 !important;
}

.sales-upcoming:nth-child(even) {
  padding-right: 0 !important;
}

.salesContent {
  position: relative;
}

.salesContent::before {
  content: '';
    position: absolute;
    width: 220px;
    height: 4px;
    background: #fff;
    left: -41px;
    transform: rotate(140deg) translate(206px, 43px);
    z-index: 9;
    top: -33px;
    display: none;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .mobile-view-css .checkWrap .checkmark{
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .salesContent::before {
    display: none;
  }
  .text-big {
    font-size: 65px !important;
  }
  .bottom-new-space {
      margin-bottom: 0px !important;
  }
  .intro-text {
      margin-top: 0px !important;
      margin-bottom: 20px !important;
  }
  .intro-text-top-space{
      margin-top: 25px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px){
  .salesContent::before {
    content: '';
    position: absolute;
    width: 225px !important;
    height: 4px !important;
    background: #fff;
    left: 38px !important;
    transform: rotate(139deg) translate(247px, 80px) !important;
    z-index: 9;
    top: -29px !important;
}
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .salesContent::before {
    display: none;
  }
}

.salesBanner {
  display: flex;
  justify-content: left;
  align-items: center;
  align-content: center;
  /* border: 1px solid #dddddd6b; */
  border-radius: 20px 0px 0 20px;
}

.ribbon-parent {
  position: relative;
  height: 80px;
}

.sales-ribbon{
  width: auto;
  height: 49px;
  line-height: normal;
  padding: 10px 0 0px 25px;
  position: absolute;
  left: 0px;
  top: 10px;
  background: #d20c14;
  color: #fff;
  font-size: 21px;
  padding-right: 20px;
}

.sales-ribbon:before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  top: -8.5px;
  left: 0.1px;
  border-bottom: 9px solid #d20c14;
  border-left: 9px solid transparent;
}

.sales-ribbon:after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  right: -21px;
  border-top: 27px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 21px solid #d20c14;
  top: 0;
}

@media screen and (min-width: 1024px)  {
  .sales-new-layout {
    width: 95% !important;
    margin: auto !important;
  }
  .live-flashsales.sale-section.sales-new-layout {
    width: 86% !important;
    margin: auto !important;
}
}


.sales-ribbon-right-parent {
  position: relative;
  height: 80px;
}

.sales-ribbon-right  {
  width: auto;
  height: auto;
  line-height: 30px;
  padding-left: 25px;
  position: absolute;
  right: 0px;
  top: 17px;
  background: #ddd;
  color: #d20c14;
  font-size: 16px;
  padding-right: 20px;
}

.sales-ribbon-right-parent .sales-ribbon-right::after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  left: -16px;
  border-top: 15px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 16px solid #ddd;
}

.sales-ribbon-right-parent .sales-ribbon-right::before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  top: -8.5px;
  right: 0.1px;
  border-bottom: 9px solid #ddd;
  border-right: 9px solid transparent;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sales-ribbon-right {
    right: -9px !important;
    font-size: 16px !important;
  }
}

@media screen and (min-width: 810px) and (max-width: 1080px) {
  /* .mob-right-space {
    padding-right: 0 !important;
  } */
  .salesContent::before {
    display: none;
  }
  .homeribbon::before {
    border-bottom-width: 26px !important;
    left: -78px !important;
  }
  .layout-space {
    margin: 0% 3% 1% 10% !important;
  }
  .profile-inner {
    margin: auto !important;
  }
  .mob-profile-menu {
    padding-left: 0 !important;
  }
  .mobile-profilr-layout {
    padding-right: 5px !important;
  }
}

.sales-bg-img {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  float: left;
  height: 100%;
  /* background-position: center; */
}


.sales-img-object {
  height: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
}

/* .sales-bg-img img,
.sale_banner_image {
  position: absolute;
  left: 0;
  right: 0;
  text-align: left;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left top;
} */

@media screen and (min-width: 320px) and (max-width: 767px) {
  .mycart-table-mob,
  .mobile-shipping-space,
  .edit-addresses,
  .desk-space-left {
    padding: 0 !important;
  }
  /* .sale_banner_image  {
    object-fit: fill !important;
  } */
  .sale-description-mobile p {
    margin-bottom: 5px;
    font-size: 18px;
  }
  .sale-description-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: auto;
    min-height: 40px;
  }
  .unsubscribe-mob {
    width: 35px;
    color: transparent !important;
    height: 35px;
    border: 1px solid transparent;
    border-radius: 50% !important;
    background-image: url('./unsubscribe.png') !important;
    background-color: unset !important;
    background-size: 29px 27px !important;
    background-repeat: no-repeat;
  }
  .subscribe-mob {
    width: 35px;
    color: transparent !important;
    height: 35px;
    border: 1px solid transparent;
    border-radius: 50% !important;
    background-image: url('./subscribe.png') !important;
    background-color: unset !important;
    background-size: 29px 27px !important;
    background-repeat: no-repeat !important;
  }
}

.sales-upcoming .upcomin-announce {
  display: flex;
  height: 140px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 0 !important;
}

.upcomin-announce {
  color: #fff;
  font-size: 15px;
}


@media screen and (min-width: 1200px)  {
  .sales-upcoming .trapezoid-upcoming::after {
    width: 23px !important;
  }
  .trapezoid-upcoming::after {
    border-bottom-width: 139px !important;
  }
}

@media only screen and (min-device-width: 0px) and (max-device-width: 767px) and (orientation: landscape) {
  .mobile-salesBanner, .salesBanner{
    height: 140px !important;
  }
  /* .salesBanner img,
  .mobile-salesBanner img {
    object-fit: fill !important;
  } */
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1253px) and (orientation: landscape) {
  .mobile-salesBanner img {
    object-fit: fill !important;
  }
}

.sales-img-object {
  display: flex;
  justify-content: center;
  align-items:  center;
  align-content: center;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 20px 0 0 20px;
}

.salesBanner img {
  max-height: 100%;
  height: 100%;
}





@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) and (orientation: landscape) {
  .mob-right-space {
   padding-right: 15px !important;
 }
   .tab-landscape-space {
     padding-right: 0 !important;
   }
   .mobile-profilr-layout {
     padding-right: 0 !important;
   }
 }
 /* .mobile-ribbon h5, .saleswrap .homeribbon h5 {
   height: 24px;
 } */


 .mobile-salesBanner img {
    height: auto;
    /* width: unset !important; */
}

.mobile-salesBanner{
  overflow: hidden;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}


.shipping-text-msg{
  color: white;
    background: #d20c14;
    font-weight: 700;
    z-index: 9999;
    padding: 10px;
    top: 0;
    position: fixed;
    right: 0;
    left: 0;
    max-width: 100%;
    margin: auto;
    width: 100%;

}

@media screen and (min-width: 1300px) and (max-width: 1368px) {
  .shipping-text-msg{
      max-width: 100%;
  }
}

.intro-text-top-space{
    margin-top: 3rem;
}

.sales-wrap {
  padding: 0 20px !important;
  margin: 0 0 55px;
}

/* .sales-wrap:nth-child(3n+1) {
  padding-left: 0 !important;
  padding-right: 40px !important;
}

.sales-wrap:nth-child(3n+3)  {
  padding-right: 0 !important;
  padding-left: 40px !important;
} */

@media screen and (max-width: 650px) {
    .shipping-text-msg {
     position: relative !important;
     z-index: 1;
    }
}
@media screen and (min-width: 0px) and (max-width: 767px) and (orientation: portrait) {
  .select_size {
      font-size: 16px !important
  }

  .products_main{
    padding: 0 !important;
  }
  /* .sales-wrap,
  .sales-wrap:nth-child(3n+1),
  .sales-wrap:nth-child(3n+3) {
    padding: 0 !important;
  }
   */
   .shipping-text-msg {
    position: relative !important;
    margin-top: 11px;
  }
  .layout-space {
    padding: 0 5% 0 4.2%;
    margin: 0 !important;
  }
  .sales-new-layout,
  .sales-wrap {
    padding: 0 !important;
  }
  .sales-wrap {
    margin: 0 0 20px !important;
  }
  .mob-top-space-intro {
    margin-top: 12px !important;
  }
}
/* 375 x 812
810 x 1080 dp  1024 x 1253*/
@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .select_size {
    font-size: 16px !important
  }

  /* .sales-wrap:nth-child(odd) {
    padding-right: 10px !important;
    padding-left: 0 !important;
  }
  .sales-wrap:nth-child(even) {
    padding-right: 0px !important;
    padding-left: 10px !important;
  } */
  .sale-description-mobile {
    height: 38px;
  }
}

@media screen and (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
  .sale-description-mobile {
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mobile-salesBanner {
  position: relative;
}

.img-wrap {
  text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.overlay{
  position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: 100%;
}

.subs-button-wrap{
  position: absolute;
    z-index: 990;
    top: 45%;
    left: 0;
    right: 0;
}

.mobile-ribbon{
  padding: 4px 5px 6px 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.sale-description-mobile {
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media screen and (min-width: 1025px) {
  .shipping-text-msg {
    height: 38px;
  }
}

.shipping-text-msg {
  height: 38px;
}

.bottom-new-space {
  margin-bottom: 26px;
}

.intro-text {
  margin-top: 35px;
}

@media screen and (min-width: 810px) and (max-width: 991px) and (orientation: portrait) {
.tab-right-space-shipping {
  padding-right: 0 !important;
}
}

/* 1024 x 1253 */
