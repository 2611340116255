.button-class{
  margin-right: 5px;
  background-color: #d20c14 !important;
  border-color:#d20c14 !important;
  border-radius:0 !important
  /* float: right; */
}

.button-div{
  float: right;
}
.input-class{
  margin-right: 10px;
}
.btn-color{
  color: #d20c14 !important;
}
.form-check-label{
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 50px;
  margin-top: 1.5rem;
  color: #d20c14;
}
.top-section{
  margin-bottom: 1.5rem;
  margin-left: 20px !important;
  margin-top: 20px !important;
}
.list-address{
  margin-left: 20px !important;
}
.checkbox-class{
  margin-top:5px;
  margin-left: 20px !important;
}
.shadow-box{
  height: 140px;
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
}
.button-class-cancel{
 background-color: white !important;
 border-color: #d20c14 !important;
 border-radius: 0 !important;
 margin-right: 5px;
 color: #d20c14 !important;
}
/* .text-color{
  color: #d00 !important
} */
.order-class{
  margin-left: 50px
}
.list-of-address{
  /* margin-top: -300px; */
}

/* Address Book Design */

.addressWrap {
  background: #fff;
  margin-bottom: 25px;
  padding: 2% 1.5% 2% 2% !important
}

.numberSection{
  font-size: 14px;
}

.actionIcons {
  color: transparent !important;
  text-decoration: none;
  background-size: 11px;
    background-repeat: no-repeat;
    background-position: top center;
    cursor: pointer;
    margin-left: 5px;
}

.ribbanIcon {
  background-image: url('../myprofile/Images/ribbon_disable.png');
}

.ribbanIcon.active {
  background-image: url('../myprofile/Images/ribbon_active.png');
}

.editIcon{
  background-image: url('../myprofile/Images/edit.png');
  background-size: 13px;
}

.deleteIcon {
  background-image: url('../myprofile/Images/delete.png');
  background-size: 13px;
}

.add_address {
  width: 100%;
  float: left;
  margin: 10px 0 20px;
  background: #fff;
  outline: 0 !important;
  font-weight: bold;
  font-size: 15px;
}

.add_address .plusAddress {
  font-size: 18px;
  line-height: normal;
}

#addaddress {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  padding: 10px;
}

.add-new_address input,
.add-new_address select,
.edit-addresses input,
.edit-addresses select{
  border: 1px solid #ddd;
  border-radius: 0 !important;
}

.btns {
  text-transform: uppercase !important;
}

button.ml-2.action-btn.btn-left.btns.btn.btn-primary {
  background: transparent !important;
}


.mob-calltoaction {
  text-align: right;
}

.mob-titles {
  padding-right: 24px;
}

.mob-line-height {
  line-height: 28px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .tab-inner {
    width: unset !important;
    margin: auto;
    padding: 0px 0 0px 8px;
}
.alignmentofVat,.alignmentofPhone,.alignmentofCity,.alignmentofBox,.alignmentofNumber{
    margin-top:8px;
}

  .addressWrap {
    padding: 6% 7% !important;
  }
  .mob-calltoaction {
    text-align: left !important;
    padding-left: 0 !important;
    
  }
  .mob-calltoaction .ribbanIcon {
    background-size: 13px;
  }
  .mob-calltoaction .editIcon{
    background-size: 16px;
    margin-left: 12px !important;
  }

  .mob-calltoaction .deleteIcon{
    background-size: 17px;
    margin-left: 12px !important;
  }

  .mob-calltoaction .ribbanIcon {
    margin-left: 14px !important;
  }

  .mob-titles {
    padding-right: 0 !important;
  }
  .mob-top-space {
    margin-top: 10px;
  }
  .mobs-space {
    padding: 0 !important;
  }
  .mob-btn-space {
    padding: 0 !important;
  }
  .mob-order {
    padding-right: 0 !important;
  }
}

.profile-form .react-tel-input .form-control {
  border-radius:  0 !important;
  padding-left: 35px !important;
  cursor: auto;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-space {
    padding-left: 0 !important;
  }
  .alignmentofPhone,.alignmentofNumber{
      margin-top:8px;
  }
  .alignmentofCity,.alignmentofLastname,.alignmentofVat,.alignmentofNumber,.alignmentofBox{
       margin-top:15px;
  }

}

.inneraddress {
  background: #fff !important;
    color: #d20c14 !important;
    border: 1px solid #fff !important;
    border-radius: 0px !important;
    margin-bottom: 19px !important;
    width: 100%;
    float: left;
    clear: both;
    font-size: 15px !important;
    font-weight: 500 !important;
    display: inline-block;
    padding: 10px!important;
}

.tab-border{
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 5px 0px 5px 5px !important;
  border-top: 0px;
}

.tab-inner{
  width: 57%;
    margin: auto;
}

.tab-inner h6 {
  font-size: 13px;
}

.edit-addresses .react-tel-input .form-control {
  width: 100% !important;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tab-inner{
    width: 75%;
      margin: auto;
  }
}

@media screen and (min-width: 1200px) {
  .edit-addresses .react-tel-input .form-control {
    width: 100% !important;
    padding-left: 17% !important;
  }
}

/* 375 x 812 */

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .order-info .float-right.numbersize {
    width: 100%;
    margin: 5px 0 10px;
  }
  .desk-dele-icon {
    display: none;
  }
  .deleteAlign {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 15px;
  }
 
  .selector:not(*:root), select  {
    box-shadow:none;
    outline:none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    border: 1px solid #dfdfdf;
    padding: 5px;
    width: 100%;
}
}

.address-book {
  padding: 20px 8px 15px 30px !important;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .address-book {
    padding: 20px 8px 30px 30px !important;
  }
}