.footerwrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #d20c14;
    z-index: 9;
}

.react-tel-input .country-list{
    z-index: 999 !important;
    max-height: 125px !important;
}
.copyrightColor{
    color:white;
}
.footerinner a {
    font-size: 14px;
    color: #fff;
    text-decoration: none;
}

.footerinner p {
    margin: 0;
}

.footerinner {
    padding: 5px 0;
}

.disclaimer-border {
    border-right: 1px solid white;
}

.copyright-footer {
    text-align: left;
    padding-left: 7px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .copyright-footer {
        text-align: center;
    }
    .disclaimer-border {
        border-right: 0;
    }
}
