.sidenav---sidenav---_2tBP{
    background-color: white !important;
}
.navIcon{
    height: 25px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-{
    margin: 30px 0
}
.sidenav---sidenav-toggle---1KRjR{
    display: none;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after{
    opacity: 0.08 !important;
}
