.hide-class{
    display: none;
  }
  a.onBtnActive{
    color: #d20c14 !important;
  }
  .alignmentofdesc{
      margin-bottom: 46px !important;
  }
.lap{
    display: block;
}
.mob{
    display: none;
}
  .left-menu {
    padding: 10px 20px;
    background: #fff;
    width: 91%;
    margin: auto;
  }

.logout-list{
    cursor: pointer;
}

.left-menu li {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #ddddddab;
}


.left-menu li:last-child {
  border: 0px solid !important;
}

.left-menu li a {
  font-size: 14px;
  color: #000;
  width: 100%;
  display: inline-block;
  padding: 10px 0;
  text-decoration: none;
}

.left-menu li a:hover {
  color: #d20c14;
}

.page-title {
  color: #000;
}

.profile-inner{
  width: 100%;
  float: left;
  /* margin-top: 3%; */
}

.padding-left-numbers.phone {
  padding-left: 35px !important;
  border-radius: 0px !important;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .address-book {
    padding: 20px 8px 30px 30px !important;
  }
  .profile-inner {
    margin: auto !important;
  }
  .lap{
      display: none;
  }
  .mob{
      display: block;
  }
  .left-menu {
    width: 100%;
  }
  .mobile-profilr-layout {
    padding: 0 4px !important;
    margin-bottom: 20px;
  }
  .mob-profile-menu{
    padding: 0 !important;
    margin: 30px 0 !important;
    margin-top: 0px !important;

  }
  .titlewrap svg {
    font-size: 16px;
  }
  .deleteAlign {
    display: none;
  }
 .overviewspace {
   margin-bottom: 20px;
  }
  .mobilewrap {
    width: 100%;
    float: left !important;
  }
  .orders-menu li a {
    padding: 10px 15px 7px 14px !important;
    font-size: 14px !important;
}
}

/* My orders design */

.orders-menu li a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 25px 6px 20px;
  position: relative;
}

.orders-menu li a.btn-active::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d20c14;
}

.orders-menu li {
  display: inline-block;
  margin-top: 16px;
}

.orders-menu {
  margin: 0;
  padding: 0;
}

.order-table {
  background: #fff;
}

.order-info {
  font-weight: normal;
  font-size: 15px;
  word-break: break-word;
}

.order-arrow{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.orderNumber {
  font-size: 13px;
}


.pro_imgs {
  width: 105px;
  height: 120px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;

}

.pro_imgs  img{
  max-width: 100%;
  max-height: 100%;
}

@media screen and (min-width: 1025px) {
  .deleteAlign {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 12px;
}
.desk-dele-icon {
  display: none;
 }
}

@media screen and (min-width: 768px) and (max-width: 1025px) {
  .deleteAlign {
    display: none;
  }
  .alignmentofdesc{
      margin-bottom: 78px !important;
  }
}

.numbersize {
  font-size: 13px;
}

.seperation {
  display: inline-block;
  margin: 0 10px;
}

.cancel-order {
  display: inline-block;
  margin-bottom: 10px;
}


.return {
    margin-top: 15px;
    display: inline-block;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .mob-order-space {
    padding: 0 !important;
  }
  .marginAlignmentForMyProfile{
      margin-bottom:-43px ;
  }
  .go-to-pickup{ 
    margin-left: 0px !important;
    width: 50%;
    padding-left: 20px;
    text-align: right;
  }
 
}

@media screen and (min-width: 768px) {
  .mob-order-space {
    padding-left: 20px !important;
  }
}

.go-to-pickup{
  margin-left: 70%;
  color:#d20c14; 
  font-weight: bold;
}
.go-to-pickup:hover{
  color:#d20c14; 
  font-weight: bold;
  text-decoration: none !important;
}