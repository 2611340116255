body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.oc-icon-status-error{
    fill:#dc3545;
}
.oc-icon-status-success{
    fill: #3AA57B;
}
.oc-icon-status-warning{
    fill: #FECA1D;
}
#global-notification .alert{
    color: #3c3c3c !important;
    background: #eee !important;
    font-size: 100% !important;
}

body.modal-open .contentwrap{
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
}

.body.modal-open nav {
    
}

.btn-danger{
    background-color: #ac0101 !important;
    border-color: #ac0101 !important;
}

#global-notification .alert .alert-content .alert-icon-container svg{
    margin: 5px 0;
}

#root{
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
}



body,
nav.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv,
.sidenav---navicon---3gCRo{
    background: #F9F9F9 !important;
}

body {
    overflow-x: hidden;
}

@media screen and (min-width: 1300px) and (max-width: 1368px) {
    #root {
        width: 100% !important;
        margin: auto;
        overflow-x: hidden;
    }
    .salesContent::before {
        left: -34px !important;
    }

}

@media all and (-webkit-min-device-pixel-ratio:0)  and (min-width: 1300px) and (max-width: 1368px) {
    .selector:not(*:root), #root {
        width: 100% !important;
        margin: auto;
        overflow-x: hidden;
    }
        
    .selector:not(*:root) .salesContent::before {
            left: -33px !important;
            width: 218px !important;
        }
    
 
  }

 

@media screen and (min-width: 1025px) {
    #root {
        width: 100%;
        margin: auto;
        overflow-x: hidden;
    }
}

@media only screen and (min-device-width: 810px) and (max-device-width: 1253px) and (orientation: landscape) {
    #root {
        width: 100% !important;
        margin: auto;
        overflow-x: hidden;
    }
    
  }

 @media screen and (min-width: 1300px) {
    section.desktop-left-content {
        width: 55px;
        float: left;
    }
    .desktop-right-content {
        width: calc(100vw - 55px + 102px);
    }
    .desktop-right-content-products {
        width: calc(100vw - 55px + 102px);
    }
    .sidenav---sidenav---_2tBP {
        position: unset !important;
    }
    .fixed-layout {
        width: 1220px;
        margin: auto;
    }
    .nav-inner.sidenav---sidenav-nav---3tvij {
        width: 50px !important;
        padding: 25px 0 !important;
        margin: 0 !important;
    }
    .cartcount {
        right: 8px !important;
    }
    .mobile-profilr-layout {
        padding-right: 0 !important;
    }
 }

 