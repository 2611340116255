/* .sale-section{
  min-height: 150px;
} */
#dutch , #french , #english{
    position:unset !important;
    margin-right: 10px;
}



.topSpace{
  margin-top: 25px;
}

.text-align {
  text-align: right;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .text-align {
  text-align: left !important;
  }
}

.intro-text p {
  margin: 0 ;
}

